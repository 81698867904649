import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './HamburgerMenu.css'; // Ensure you link to your CSS file

function HamburgerMenu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="mini-header">
            <img src="/logo192.png" alt="Logo" className="header-logo" />
            <button className="hamburger-icon" onClick={toggleMenu}>
                ☰
            </button>
            {isOpen && (
                <div className="menu-list">
                    <Link to="/" onClick={toggleMenu}>Home</Link>
                    <Link to="/profiles" onClick={toggleMenu}>Meet the Team</Link>
                    <a href="mailto:sunshineconsulting2023@gmail.com" onClick={toggleMenu}>Contact Us</a>
                </div>
            )}
        </div>
    );
}

export default HamburgerMenu;
