    import React from 'react';
    import './ProfilePage.css';
    import { Link } from 'react-router-dom';
    import HamburgerMenu from './HamburgerMenu.js';

    function Profile({ name, title, bio, image }) {
        return (
            <div className="profile">
                <div className="profile-header">
                    <img src={image} alt={name} />
                    <div>
                        <h3>{name}</h3>
                        <h4>{title}</h4>
                    </div>
                </div>
                <p className="profile-bio">{bio}</p>
            </div>
        );
    }

   
    const vivianBio = "Vivian is a seasoned corporate attorney and has worked at prestigious law firms including Flex by Fenwick and Gunderson Dettmer LLP, where she advised startups and ECVCs on a wide array of corporate matters. Vivian's legal acumen extends to corporate governance, financing strategies, compliance, and risk assessment, where she consistently delivers strategic guidance tailored to the dynamic needs of her clients.\n\nWith a comprehensive understanding of corporate law and a track record of delivering exemplary legal counsel, Vivian stands as a trusted advisor to her clients, navigating them through the intricate landscape of corporate governance and compliance with confidence and expertise. Her dedication to staying abreast of evolving legal landscapes ensures that her clients receive timely guidance and strategic solutions tailored to their specific needs. Vivian's commitment to excellence and her ability to foster collaborative relationships across various stakeholders make her an invaluable asset to any legal team."
    //const jillBio = "Jill focuses on general corporate counseling, venture capital financings and mergers and acquisitions. She is a graduate of Harvard Law School, and has worked at Latham & Watkins, Sidley Austin and Gunderson Dettmer. She has experience handling complex structured finance transactions, high-yield bond offerings and acquisition financings/LBOs. She has also represented a wide variety of technology companies from consumer internet, software, telecommunications and entertainment technology industries, as well as a number of leading venture capital firms in venture capital financings where she negotiated deal documents, managed due diligence review and coordinated closing deliverables.\n\nNow at QT Consulting, Jill applies her deep understanding of corporate law to assist emerging companies in navigating legal complexities. Her ability to communicate effectively across cultures is enhanced by her fluency in Mandarin and proficiency in German and French. Jill’s dynamic approach to law and commitment to advancing corporate interests, combined with her strategic insights into the tech and life sciences sectors, make her an invaluable asset to our team."
    const aliceBio = "Alice is an experienced attorney with a strong focus on serving tech clients. As a former associate at Gunderson Dettmer, LLP, Alice has garnered extensive experience in managing complex legal matters, especially in the technology sector. Her role involved handling intricate M&A transactions, intellectual property issues, and privacy matters.\n\nAlice has an extensive background in both Taiwanese and American legal environments, and has successfully bridged the gap between these two cultures, showcasing an exceptional ability to adapt and excel in multicultural settings.\n\nShe is barred in California, New York and Taiwan."

    function ProfilePage() {
        return (
            <div className="ProfilePage">
                <HamburgerMenu />
                <h1>Our Lawyers</h1>
                <div className="profiles">
                    <Profile
                        name="Vivian Liu"
                        bio={vivianBio}
                        image="https://project-j-serve.s3.amazonaws.com/images/vivian.jpeg"
                    />
                    {/*}
                    <Profile
                        name="Jill He"
                        bio={jillBio}
                        image="https://project-j-serve.s3.amazonaws.com/images/jill.jpeg"
        />*/}
                    <Profile
                        name="Alice Lu"
                        bio={aliceBio}
                        image="https://project-j-serve.s3.amazonaws.com/images/alice.jpeg"
                    />

                    {/*
                    <Profile
                        name="Ludwig von Streeterville"
                        title="Senior Legal Counsel"
                        bio="Ludwig von Streeterville, affectionately known as the 'Alley Cat Attorney,' has clawed his way from the gritty streets of Chicago to the plush law office sofas. With a fierce determination forged in the urban jungle, Ludwig specializes in property disputes and alley acquisitions, bringing a street-smart savvy to the firm that's unmatched. When he's not busy laying down the law or sprawling on top of legal briefs (literally), Ludwig enjoys patrolling the office perimeters, ensuring no unauthorized snacks go unnoticed. Despite his tough exterior, Ludwig's clients often praise his ability to disarm the toughest negotiations with just a purr, proving that even street cats can look at kings—or opposing counsel."
                        image="https://i.imgur.com/hEjC1F9.jpeg"
                    />
                    <Profile
                        name="Tiffany"
                        title="Legal Advisor"
                        bio="Tiffany, known around the office as the 'Pampered Purr-suader,' has lived a life of luxury that she brings into her practice of law. Never one to shy away from the spotlight, Tiffany specializes in public relations and charm offensives, handling the firm's most delicate cases with a soft paw and a sharp mind. Her expertise in getting what she wants, whether it's more treats or favorable terms in a settlement, makes her an invaluable asset to the team. On days off, Tiffany can be found lounging on silk cushions or supervising her humans from her well-appointed perch by the window. Clients find comfort in her calm, commanding presence, knowing she'll handle their cases with the same care she demands for her grooming."
                        image="https://i.imgur.com/km4TV0u.jpeg"
                    />
        */}
                </div>
                <Link to="/" className="back-link">← Back to Home</Link>
            </div>
        );
    }

    export default ProfilePage;
