import React from 'react';
import './HomePage.css';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu.js';

function Header() {
    return (
        <header className="App-header">
            <h1>Welcome to QT Consulting</h1>
        </header>
    );
}

function Banner() {
    return (
        <div className="App-banner">
            <img src="https://project-j-serve.s3.amazonaws.com/images/hero_banner.webp" alt="Law Firm Office" />
            <h2>Your partner in all legal matters</h2>
        </div>
    );
}

function MainContent() {
    return (
        <main className="App-content">
            <section className="about-us">
                <h2>About Us</h2>
                <p>We are a dedicated team of seasoned legal professionals, specializing in providing comprehensive counsel tailored to the dynamic needs of startups, entrepreneurs, and established businesses alike.</p>
                <div className="profiles-link">
                    <Link to="/profiles" className="link-button">Meet Our Lawyers</Link>
                </div>
            </section>
            <section className="our-services">
                <h2>Our Services</h2>
                <p>Our firm's core focus lies in navigating the intricate landscape of venture capital funding, empowering emerging tech enterprises to thrive and innovate. From seed-stage investments to complex mergers and acquisitions, we offer strategic guidance and meticulous attention to detail to ensure our clients' success in an ever-evolving market. Committed to excellence and driven by a passion for the law, QT Consulting stands as a trusted partner in fostering growth and facilitating transformative transactions within the vibrant realm of technology and commerce.</p>
            </section>
        </main>
    );
}



function Footer() {
    return (
        <footer className="App-footer">
            <p>Contact us at: <a href="mailto:sunshineconsulting2023@gmail.com" class="contact-us">QT Consulting</a></p>
        </footer>
    );
}



function HomePage() {
    return (
        <div className="App">
            <HamburgerMenu />
            <Header />
            <Banner />
            <MainContent />
            <Footer />
        </div>
    );
}

export default HomePage;
